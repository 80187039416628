import {
  getCountryCodeName,
  getCountryList,
} from '@guider-global/front-end-utils';
import { getUniqueElements } from '@guider-global/isomorphic-utils';
import {
  useOrganization,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { IChartsUser, IFilterModel } from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  Stack,
  getCustomGridAutocompleteFilterOperator,
  getCustomGridDateFilterOperator,
} from '@guider-global/ui';
import {
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { UserSearchFilterContainer } from 'containers';
import { MongoMetricsContainer } from 'containers/MongoMetricsContrainer';
import { useAppContext } from 'context';
import { ITableModels } from 'context/OverviewChartContext/types';
import { format, formatDuration } from 'date-fns';
import {
  getGenderLabel,
  getOrganizationColumn,
  getSanityProfileFieldColumns,
} from 'functions';
import { useAdminChartsUsers, useAdminPrograms, useSnackbar } from 'hooks';
import { useOrganizations } from 'hooks/useOrganizations';
import { getUserRoleColumn } from 'pages/MongoMetricsPage';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 75, 100];

export function UserSearchContainer() {
  const navigate = useNavigate();
  const { organizationSlug, isRootDomain } = useAppContext();
  const { setError } = useSnackbar();

  const countryOptions: IMultiSelectChipFilterOption[] = useMemo(
    () =>
      getCountryList({ countryCode: 'GB', languageCode: 'en' }).map(
        ({ key, countryName }) => ({
          id: key,
          label: countryName,
          color: 'gray',
          variant: 'outlined',
        }),
      ),
    [],
  );

  const { adminPrograms } = useAdminPrograms({
    params: {
      organizationSlug: organizationSlug,
      localeCode: 'en_GB',
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Programs Metrics',
        });
      },
    },
  });

  const { getPrograms } = useSanityOrganizationPrograms({
    organizationSlug: organizationSlug,
    localeCode: 'en_GB',
    getSilently: true,
  });
  const programs = getPrograms();
  const { getBaseLanguage } = useSanityBaseLanguage({
    organizationSlug,
    localeCode: 'en_GB',
    getSilently: false,
  });
  const baseLanguage = getBaseLanguage();

  const { organizations } = useOrganizations({
    getSilently: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/organizations' : undefined,
  });

  const organizationsDetails = organizations();

  const traineeOrganizationLabel = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.trainee
        ?.singular,
  );

  const guideOrganizationLabel = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.guide
        ?.singular,
  );

  const traineeLabel = getUniqueElements(traineeOrganizationLabel, 'Trainee');

  const guideLabel = getUniqueElements(guideOrganizationLabel, 'Guide');

  const { organization: sanityOrganization } = useOrganization({
    organizationSlug: organizationSlug ?? 'guider',
    showHiddenProfileData: true,
  });

  const sanityOrganizationPersonalDetails =
    sanityOrganization?.personal_details;

  const today = new Date().toLocaleDateString();
  const sanityOrganizationFields = sanityOrganization?.profile_fields ?? [];
  const isSanityOrganizationCountryEnabled =
    sanityOrganization?.personal_details.country.enabled ?? false;

  const userOrganizationField = getSanityProfileFieldColumns<IChartsUser>({
    fields: sanityOrganizationFields,
    customFieldId: (id) => `organizationFields.${id}`,
    getColumnOptions: (id) => ({
      minWidth: 130,
      hideable: true,
      valueGetter: ({ row }) => {
        const fieldSlug: keyof IChartsUser['organizationFields'] = id;
        const organizationFields = row.organizationFields;

        return organizationFields[fieldSlug];
      },
    }),
  });

  const sanityGenderProfileField =
    sanityOrganizationPersonalDetails?.gender.enabled;
  const sanityGenderOptions = baseLanguage?.profile_fields?.gender?.options;

  const getGenderColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'gender',
        headerName: 'Gender',
        valueFormatter: getGenderLabel(sanityGenderOptions),
        valueGetter: getGenderLabel(sanityGenderOptions),
      });
    }
    return column;
  };

  const getCountryColumn = (field: string, enabled: boolean) => {
    if (enabled) {
      return [
        {
          field,
          headerName:
            sanityOrganizationPersonalDetails?.country.custom_label ??
            'Country',
          valueGetter: ({ value }: GridValueGetterParams<any, any>) =>
            getCountryCodeName(value),
          minWidth: 150,
          flex: 0.3,
          filterOperators: [
            getCustomGridAutocompleteFilterOperator({
              componentProps: {
                options: countryOptions,
              },
            }),
          ],
        },
      ];
    }
    return [];
  };

  const [userModel, setUserModel] = useState<ITableModels>({
    paginationModel: { page: 0, pageSize: 15 },
    sortModel: [],
    filterModel: undefined,
  });

  const { adminChartsUsers = [], isLoadingAdminChartsUsers } =
    useAdminChartsUsers({
      params: {
        page: userModel.paginationModel.page + 1,
        pageLimit: userModel.paginationModel.pageSize,
        sortModel: userModel.sortModel,
        filterModel: userModel.filterModel,
      },
    });

  const userColumns: GridColDef<IChartsUser>[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      minWidth: 100,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    ...getOrganizationColumn(organizationsDetails, isRootDomain),
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.5 },
    {
      field: 'givenName',
      headerName: 'First name',
      minWidth: 100,
    },
    { field: 'familyName', headerName: 'Last name', minWidth: 100 },
    {
      ...getUserRoleColumn(guideLabel, traineeLabel),
    },
    {
      field: 'programsWhereIsGuide',
      headerName: `${guideLabel} profiles`,
      minWidth: 200,
      valueFormatter: ({ value }) => getProgramsLabelsBySlugs(value),
    },
    {
      field: 'programsWhereIsTrainee',
      headerName: `${traineeLabel} profiles`,
      minWidth: 200,
      valueFormatter: ({ value }) => getProgramsLabelsBySlugs(value),
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
      type: 'number',
    },
    {
      field: 'concludedRelationships',
      headerName: 'Concluded relationships',
      type: 'number',
    },
    {
      field: 'status',
      headerName: 'Status',
      valueFormatter: ({ value }) => parsedProfileStatus(value),
    },
    { field: 'sessionsHeld', headerName: 'Sessions held', type: 'number' },
    {
      field: 'totalSessionMinutes',
      headerName: 'Total attendance',
      type: 'number',
      valueFormatter: ({ value }) => {
        return formatDuration({ minutes: value }, { zero: true });
      },
      width: 110,
    },
    { field: 'jobRole', headerName: 'Job role' },
    ...getGenderColumn(sanityGenderProfileField),
    { field: 'townOrCity', headerName: 'Town/City' },
    ...getCountryColumn('country', isSanityOrganizationCountryEnabled),
    { field: 'goals', headerName: 'Goals', type: 'number' },
    ...userOrganizationField,
  ];

  function getProgramsLabelsBySlugs(
    programSlug: string | number | string[] | Date | undefined,
  ): string {
    const programsSlugs = Array.isArray(programSlug)
      ? programSlug.map(String)
      : (String(programSlug).split(';') || []).map((slug) => slug.trim());

    const programsLabels = programsSlugs
      .map(
        (slug) =>
          adminPrograms?.find((program) => program.programSlug === slug)
            ?.programName,
      )
      .filter((label) => label !== undefined);

    return programsLabels.length > 0 ? programsLabels.join(', ') : 'n/a';
  }

  function handleEvent(params: GridRowParams): void {
    navigate(`/users/${params.id}`);
  }

  // Render
  return (
    <>
      <Stack direction="row" width="100%" pb={3} px={1}>
        <UserSearchFilterContainer
          userModel={userModel}
          updateUserModel={(model) => {
            setUserModel((currentUserModel) => ({
              ...currentUserModel,
              ...model,
            }));
          }}
          countryOptions={countryOptions}
          countryEnabled={isSanityOrganizationCountryEnabled}
        />
      </Stack>
      <MongoMetricsContainer
        reportName="users"
        filename={`${organizationSlug ?? 'guider'}-all-users-${today}`}
        metricsTableProps={{
          ...userModel,
          heading: 'All users',
          rows: adminChartsUsers,
          columns: userColumns,
          loading: isLoadingAdminChartsUsers,
          onRowClick: handleEvent,
          filterMode: 'server',
          onFilterModelChange: (model) => {
            setUserModel((currentUserModel) => ({
              ...currentUserModel,
              filterModel: model as IFilterModel,
            }));
          },
          sortingMode: 'server',
          onSortModelChange: (sortModel) => {
            setUserModel((currentUserModel) => ({
              ...currentUserModel,
              sortModel,
            }));
          },
          pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
          paginationMode: 'server',
          onPaginationModelChange: (model) => {
            setUserModel((currentUserModel) => ({
              ...currentUserModel,
              paginationModel: model,
            }));
          },
        }}
      />
    </>
  );
}

const parsedProfileStatus = (status?: string) => {
  switch (status) {
    case 'matched':
      return 'Matched';
    case 'joinedProgram':
      return 'Program profile created';
    case 'completedProfile':
      return 'Signed up';
    case 'signedUp':
      return 'Signed up';
    default:
      return status;
  }
};

import { AlertBox, Loading, Stack } from '@guider-global/ui';

import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import { useAdminUserProfiles, useProfileProgramOverviews } from 'hooks';

import { getSubDomain } from '@guider-global/front-end-utils';
import {
  GroupProgramMembershipsContainer,
  IndividualProgramMembershipsContainer,
  OpenMatchingRelationshipsContainer,
} from 'containers';
import { useLocalization } from 'hooks/useLocalization';
import { useEffect, useMemo } from 'react';

export type ProfileProgramOverviewListContainerProps = {
  profileId: string;
};

export function ProfileProgramOverviewListContainer({
  profileId,
}: ProfileProgramOverviewListContainerProps) {
  const { profileProgramOverviews, areProfileProgramOverviewsFetching } =
    useProfileProgramOverviews();

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  // Profiles

  const { adminUserProfiles, isLoadingAdminUserProfiles } =
    useAdminUserProfiles({});

  const userProfile = adminUserProfiles().at(0);
  const userEmail = userProfile?.email ?? 'N/A';
  const userDisplayName = userProfile?.displayName ?? 'N/A';
  const userOrgSlug = userProfile?.organizationSlug ?? 'N/A';

  const userData = useMemo(
    () => ({
      userEmail,
      userDisplayName,
      userProfileId: profileId,
      userOrgSlug,
    }),
    [userEmail, userDisplayName, profileId, userOrgSlug],
  );

  // Programs
  const { getPrograms, refetchPrograms, isLoadingSanityPrograms } =
    useSanityOrganizationPrograms({
      getSilently: true,
      localeCode,
      organizationSlug,
    });

  const programs = getPrograms()!;
  const programsOrgSlug =
    programs[0]?.metadata?.organization?.basic_info?.subdomain?.current;

  useEffect(() => {
    if (userOrgSlug !== programsOrgSlug) {
      refetchPrograms(userOrgSlug, localeCode ?? 'en_GB');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsOrgSlug, userOrgSlug]);

  const programData = useMemo(
    () =>
      programs.map((program) => {
        const variation = program.program_details?.program_variation;
        const overview = profileProgramOverviews.find(
          (overview) => overview.programSlug === program.metadata.id.current,
        );

        return {
          program,
          variation,
          overview,
        };
      }),
    [programs, profileProgramOverviews],
  );

  const programDataWithOverviews = useMemo(
    () => programData.filter(({ overview }) => overview),
    [programData],
  );

  const hasOpenMatchingProfile =
    userProfile?.roles && userProfile?.roles?.length !== 0;

  if (
    areProfileProgramOverviewsFetching ||
    isLoadingAdminUserProfiles() ||
    isLoadingSanityPrograms()
  ) {
    return <Loading sx={{ mt: '10vh', mb: '50vh' }} />;
  } else {
    if (programDataWithOverviews.length === 0 && !hasOpenMatchingProfile) {
      return (
        <AlertBox
          iconVariant="warning"
          variant="iconWithBackground"
          title={'No program memberships'}
          description={'This user is not a member of any programs yet'}
          sx={{ my: '50px' }}
        />
      );
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      {hasOpenMatchingProfile && <OpenMatchingRelationshipsContainer />}
      {programData.map(({ program, variation, overview }, index) => {
        if (!overview) {
          return null;
        } else {
          if (variation === 'group') {
            return (
              <GroupProgramMembershipsContainer
                profileProgramOverview={overview}
                program={program}
                key={program._id || `group-${index}`}
                userData={userData}
              />
            );
          } else if (variation === 'individual') {
            return (
              <IndividualProgramMembershipsContainer
                profileProgramOverview={overview}
                program={program}
                key={program._id || `individual-${index}`}
                userData={userData}
              />
            );
          } else {
            return null;
          }
        }
      })}
    </Stack>
  );
}
